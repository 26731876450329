/* Mobile look first */
html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: "Saira", sans-serif;
  --br: 6px;
  --w: 480px;
  background-color: #282c34;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #231f20;
}

p {
  font-size: 16px;
  font-weight: 400;
  margin: 2px 0;
  text-align: left;
}

a {
  color: inherit;
}

/* scrollbar */

/* Auto-hide scrollbar when not scrolling (Webkit browsers only) */
html {
  scrollbar-color: auto; /* for Firefox */
  scrollbar-width: thin; /* for Firefox */
  overflow: -moz-scrollbars-none; /* Old Firefox */
}

body::-webkit-scrollbar {
  width: 0px; /* Hides scrollbar for IE and Edge */
  background: transparent; /* Hides scrollbar for Chrome, Safari, and Opera */
}

body:hover::-webkit-scrollbar {
  width: 8px; /* Shows scrollbar when hovering over the body */
}

::-webkit-scrollbar {
  width: 8px;  /* or any other measurements */
  height: 8px;  /* necessary for horizontal scrollbars */
}

/* Styles the draggable part of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

/* Styles the (optional) buttons on the scrollbar */
::-webkit-scrollbar-button {
  display: none;
}

/* Styles the track (part the thumb slides within) */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}



.App {
  max-width: var(--w);
  min-height: 100vh;
  height: 100vh;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  overflow: auto;
}

.container {
  margin: 0rem 0rem 4rem 0rem;
  padding-bottom: 60px;
  width: 80%;
}

.dropdown {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.selectTestType {
  font-size: 16px;
  font-weight: 550;
  margin: 2px 0;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.logo,
.clickable {
  cursor: pointer;
}

.logo {
  width: 56px;
  height: 56px;
  text-align: left;
  display: flex;
}

.title {
  font-size: 1.9em;
}

.section-title {
  display: grid;
  background: url(../src/img/verifier-bg-mobile.png) no-repeat center bottom;
  background-size: cover;
  width: 100%;
  color: white;
  height: 25vh;
  min-height: 150px;
  margin-bottom: 20px;
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  margin: 0 0 5px;
}

/* NAVIGATION */
.navigation_bar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
}

.verifier-icon {
  height: 45px;
  width: 45px;
}

.navigation-title {
  font-size: 32px;
  font-weight: 300;
  margin: 0;
}

.navbar .site-navigation {
  position: absolute;
  top: 1rem;
  right: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 1;
}
/* i18n */
.navbar {
  background: #ffffff;
  grid-area: nav;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
}

.navbar .site-navigation {
  position: absolute;
  top: 1rem;
  right: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 1;
}


.lang-select {
  padding: 5px;
  height: 30px;
}

.btn-primary-navigation {
  background-color: #231f20;
  color: #ffffff;
  height: 30px;
  border-radius: var(--br);
  border: 1px solid #231f20;
  cursor: pointer;
  transition: all 0.3s;
  padding: 0 10px;
  min-width: 75px;
}

.nav-links {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
  padding: 0;
  list-style: none;
  gap: 1rem;
}

/* END OF NAVIGATION */

/* LOGIN */
.login-container {
  width: 80%;
}

.login-container h2 {
  text-align: left;
  font-weight: 300;
  font-size: 36px;
  margin: 20px 0;
}

.login-container button {
  max-width: none;
}

/* FOOTER */
.visual-footer {
  position: fixed;
  bottom: 0;
  font-size: 12px;
  color: #9b9b9b;
  text-align: center;
  width: 472px !important;
  word-break: break-word;
  padding: 0.5rem 0rem;
  background: linear-gradient(to top, white 90%, rgba(245, 245, 245, 0.1));

}

.visual-footer p {
  padding: 0rem 2rem;
}

/* END OF FOOTER */

/* BUTTONS */
.btn-primary {
  background-color: #231f20;
  color: #ffffff;
  font-size: 17px;
  width: 100%;
  max-width: 300px;
  height: 52px;
  border-radius: var(--br);
  cursor: pointer;
  margin: 10px 0;
}

.btn-secondary {
  background-color: #ffffff;
  color: #231f20;
  width: 160px;
  font-size: 17px;
  height: 42px;
  border-radius: var(--br);
  cursor: pointer;
  margin: 20px 0;
}
/* END BUTTONS */

/* OUTPUT LOGS */
.log-section {
  margin-top: 4rem;
  text-align: left;
}

.log-section textarea {
  margin-top: 10px;
  width: 100%;
  height: 300px;
  max-width: 390px;
}

.copy-text {
  margin-left: 10px;
  color: green;
}

/* END OUTPUT LOGS */

.datalist-container {
  /*width: 80%; */
  margin: auto;
  overflow-x: auto;
}

.table {
  justify-content: center;
  width: 100%;
  /*display: flex;*/
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 17px;
  border-spacing: 0;
  margin-bottom: 3rem;
}
tr {
  background-color: white;
}

td {
  /*width: 0px;*/
  padding: 10px;
  border-bottom: 2px solid #f2f2f2;
  white-space: nowrap;
  min-height: 42px;
}

.col-1 {
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 600;
  text-align: left;
}

.col-2 {
  /*display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    color: rgba(0, 0, 0, 0.4);*/
  text-align: right;
}
.header {
  font-weight: bold;
  color: #000;
}

/* TABLE */

/* END OF TABLE */

@media screen and (max-width: 425px) {
  .visual-footer p {
    font-size: 12px;
    padding: 0.5rem 2rem;
    max-width: 425px;
  }
}
